import {
  Badge,
  DataTable,
  Layout,
  LegacyCard,
  LegacyStack,
  LinkAction,
  Page,
  Pagination,
  Select,
  SelectOption,
} from '@shopify/polaris'
import { DateStyle } from '@shopify/react-i18n'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { Paginator } from '../common/api'
import { DatePickerField } from '../common/DatePickerField'
import { Footer } from '../common/Footer'
import { FormattedDate, InternalLink, OrderLink } from '../common/helpers'
import { BadgeStatus } from '../common/SubscriptionStatusBadge'
import * as urls from '../common/urls'
import {
  FulfillmentEvent,
  FulfillmentOrderStatus,
  PageQuery,
  usePageQuery,
} from './DashboardFulfillmentOrdersPage'

interface TableProps {
  items: FulfillmentEvent[]
  status: FulfillmentOrderStatus
  footerContent: React.ReactNode
}

const StatusMapping: Record<FulfillmentOrderStatus, BadgeStatus> = {
  Scheduled: 'new',
  Open: 'success',
}

const statusOptions: SelectOption[] = [
  { value: 'Scheduled', label: 'Scheduled' },
  { value: 'Open', label: 'Open' },
]

const Table = (props: TableProps) => {
  const rows = props.items.map((item) => [
    <InternalLink url={urls.subscriptionUrl(item.subscriptionRestId)}>
      #{item.subscriptionRestId}
    </InternalLink>,
    item.orderGid ? <OrderLink gid={item.orderGid}>Order {item.orderName}</OrderLink> : null,
    item.customerName,
    <Badge status={StatusMapping[item.status as FulfillmentOrderStatus]}>{item.status}</Badge>,
    item.amount,
    <FormattedDate
      date={new Date(item.date)}
      format={{ style: DateStyle.Short, hour: 'numeric', minute: 'numeric' }}
      titleFormat={{ style: DateStyle.Long, hour: 'numeric', minute: 'numeric' }}
    />,
  ])

  return (
    <DataTable
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
      headings={['Subscription ID', 'Order', 'Customer', 'Status', 'Amount', 'Date']}
      rows={rows}
      verticalAlign="middle"
      footerContent={props.footerContent}
    />
  )
}

export const PageContent = ({ paginator }: { paginator: Paginator<FulfillmentEvent> }) => {
  const history = useHistory()
  const pageQuery = usePageQuery()

  const updateParams = useCallback(
    (newParams: Partial<PageQuery>) => {
      history.replace(urls.dashboardFulfillmentsUrl({ ...pageQuery, ...newParams }))
    },
    [pageQuery, history]
  )

  const backAction: LinkAction = {
    content: 'Dashboard',
    url: urls.dashboardUrl({ date: pageQuery.date }),
  }

  return (
    <Page
      title="Fulfillments schedule"
      backAction={backAction}
      primaryAction={
        <LegacyStack>
          <Select
            label="Status: "
            labelInline
            options={statusOptions}
            value={pageQuery.status}
            onChange={(status) =>
              updateParams({ status: status as FulfillmentOrderStatus, page: 1 })
            }
          />
          <DatePickerField
            date={pageQuery.date}
            onChange={(date) => updateParams({ date, page: 1 })}
          />
        </LegacyStack>
      }
    >
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <Table
              items={paginator.data}
              status={pageQuery.status}
              footerContent={
                <Pagination
                  label={`Page: ${pageQuery.page} of ${paginator.last_page}`}
                  hasPrevious={!!paginator.prev_page_url}
                  onPrevious={() => updateParams({ page: pageQuery.page - 1 })}
                  hasNext={!!paginator.next_page_url}
                  onNext={() => updateParams({ page: pageQuery.page + 1 })}
                />
              }
            />
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <Footer />
        </Layout.Section>
      </Layout>
    </Page>
  )
}
