import { Loading } from '@shopify/polaris'
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { backendFetchResultData, Paginator } from '../common/api'
import { formatLocalISODate, queryString, useQuery } from '../common/helpers'
import { LoadErrorPage } from '../common/LoadErrorPage'
import { TabIndex, Tabs } from '../common/Tabs'
import { PageContent } from './PageContent'
import { Skeleton } from './Skeleton'

export interface FulfillmentEvent {
  subscriptionRestId?: number
  customerName?: string
  status: string
  date: string
  amount?: string
  orderGid?: string
  orderName?: string
}

const fetchDashboardFulfillments = (
  _: string,
  date: string,
  status: string,
  page: number
): Promise<Paginator<FulfillmentEvent>> =>
  backendFetchResultData(
    'GET',
    `/dashboard/fulfillments?${queryString({
      date,
      status,
      page,
    })}`
  )

export type FulfillmentOrderStatus = 'Scheduled' | 'Open'

export interface PageQuery {
  date: string
  status: FulfillmentOrderStatus
  page: number
}

export const usePageQuery = (): PageQuery => {
  const query = useQuery()

  const date = query.get('date') ?? formatLocalISODate(new Date())
  const status = (query.get('status') ?? 'Open') as FulfillmentOrderStatus
  const page = Number.parseInt(query.get('page') ?? '1') || 1

  return useMemo(
    () => ({
      date,
      status,
      page,
    }),
    [date, status, page]
  )
}

export const DashboardFulfillmentOrdersPage = () => {
  const pageQuery = usePageQuery()

  const {
    data: paginator,
    error,
    isValidating,
  } = useSWR(
    ['dashboard-fulfillments', pageQuery.date, pageQuery.status, pageQuery.page],
    fetchDashboardFulfillments
  )

  const [loadedPaginator, setLoadedPaginator] = useState<Paginator<FulfillmentEvent> | null>(null)

  useEffect(() => {
    if (paginator) {
      setLoadedPaginator(paginator)
    }
  }, [paginator])

  return (
    <>
      {isValidating && <Loading />}
      <Tabs selected={TabIndex.Dashboard} />

      {error && <LoadErrorPage />}
      {!error && !loadedPaginator && <Skeleton />}
      {!error && loadedPaginator && <PageContent paginator={loadedPaginator} />}
    </>
  )
}
