import {
  Banner,
  ContextualSaveBar,
  Form as PolarisForm,
  FormLayout,
  Text,
  TextField,
} from '@shopify/polaris'
import { Formik, useFormikContext } from 'formik'
import { useCallback, useState } from 'react'

import { Result } from '../common/api'
import {
  InternalLink,
  ResultToastOrBanner,
  useFormikHandleChangeCallback,
  useShopFlag,
  useShopPermission,
} from '../common/helpers'
import * as urls from '../common/urls'
import { MailSettings, putMailSettings } from './api'

const Inner = () => {
  const formik = useFormikContext<MailSettings>()
  const handleChange = useFormikHandleChangeCallback(formik.setFieldValue)

  const emailTemplatesFlag = useShopFlag('custom_email_templates')
  const customEmailTemplatesEnabled = useShopPermission('customEmailTemplatesEnabled')
  const customEmailTemplatesAccess =
    emailTemplatesFlag === 'enabled' ||
    (customEmailTemplatesEnabled && emailTemplatesFlag === 'by-plan')

  return (
    <FormLayout>
      {!customEmailTemplatesAccess && (
        <Banner status="warning">
          <Text as="p">
            Please{' '}
            <InternalLink url={urls.pricingPlansUrl()} target={'_blank'}>
              upgrade
            </InternalLink>{' '}
            to our <Text as="strong">Pro Plan</Text> to unlock advanced email template editing
            features. Tailor your communications with ease, creating custom emails that resonate
            with your audience.
          </Text>
        </Banner>
      )}
      <TextField
        id="subject"
        name="subject"
        label="Email subject"
        type="text"
        value={formik.values.subject}
        error={formik.touched.subject && formik.errors.subject}
        onChange={handleChange}
        disabled={!customEmailTemplatesAccess}
        autoComplete="off"
      />
      <TextField
        id="html"
        name="html"
        label="Email body (HTML)"
        type="text"
        multiline={true}
        value={formik.values.html}
        error={formik.touched.html && formik.errors.html}
        onChange={handleChange}
        disabled={!customEmailTemplatesAccess}
        autoComplete="off"
      />
    </FormLayout>
  )
}

interface FormProps {
  mailType: string
  initialValues: MailSettings
}

export const Form = ({ mailType, initialValues }: FormProps) => {
  const [saveResult, setSaveResult] = useState<Result<any> | null>(null)

  const handleSubmit = useCallback(
    async (settings: MailSettings, actions: any) => {
      actions.setSubmitting(true)
      const res = await putMailSettings(mailType, settings)
      actions.setSubmitting(false)

      setSaveResult(res)
    },
    [mailType]
  )

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {(formik) => (
        <PolarisForm onSubmit={formik.handleSubmit}>
          <ResultToastOrBanner result={saveResult} setResult={setSaveResult} />

          <Inner />

          {formik.dirty && (
            <ContextualSaveBar
              message="Unsaved changes"
              saveAction={{
                onAction: formik.handleSubmit,
                loading: formik.isSubmitting,
                disabled: false,
              }}
              discardAction={{
                onAction: formik.resetForm,
              }}
            />
          )}
        </PolarisForm>
      )}
    </Formik>
  )
}
